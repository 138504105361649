<template>
  <div class="step_container">
      <a-form-model layout='horizontal'
      ref="person_second_form"
      :rules='rules'
      :model="secondForm"
      :label-col="labelCol"
      :wrapper-col="wrapperCol">
    <h1 class="step_title">ACCOUNT BEHAVIOUR BASELINE 账户行为基准</h1>
    <div class="step_question_container">
      <h2 class="step_question_title">
       SOURCE OF FUNDS/WEALTH AND PURPOSE OF TRADE  资金来源和交易目的
      </h2>

        <a-form-model-item
        prop="source_of_funds_text"
        label="Soure of Funds/Wealth 资金来源">
          <a-select
            :disabled="disabled"
              v-model="secondForm.source_of_funds_text"
            >
            <a-select-option
            v-for="item in sof_list"
            :key='item.id'
            :value="item.desc">{{item.desc}}</a-select-option>
            </a-select>
        </a-form-model-item>
        <a-form-model-item
        v-show="source_of_funds_text === 'Other'"
        prop="source_of_funds_other_text"
        label="其他资金来源">
          <a-textarea
            :disabled="disabled"
            placeholder=""
            v-model='secondForm.source_of_funds_other_text'
            @change='(e)=>{
              handleInput(e,"source_of_funds_other_text")
            }'
            :auto-size="{ minRows: 3, maxRows: 3 }"
          />
          <div class="textarea_desc">I.e.Personal savings in bank in Australia/New Zealand; sale of another property in Australia/China; inheritance; health products trading revenue; cosmetics importing and exporting revenue./Employment wages and salaries</div>
        </a-form-model-item>
         <a-form-model-item
        prop="purpose_of_transaction_text"
        label="Purpose of Transaction 换汇目的">
          <a-select
              :disabled="disabled"
               v-model='secondForm.purpose_of_transaction_text'
            >
            <a-select-option
            v-for="item in pof_list"
            :key='item.id'
            :value="item.desc">{{item.desc}}</a-select-option>
            </a-select>
        </a-form-model-item>
        <a-form-model-item
        v-show="secondForm.purpose_of_transaction_text === 'Other'"
        prop="purpose_of_transaction_other_text"
         label="其他换汇目的">
          <a-textarea
      placeholder=""
      :disabled="disabled"
      v-model='secondForm.purpose_of_transaction_other_text'
      @change='(e)=>{
        handleInput(e,"purpose_of_transaction_other_text")
      }'
      :auto-size="{ minRows: 3, maxRows: 3 }"
    />
    <div class="textarea_desc">I.e.Repatriate overseas salary back to Australia/New Zealand; purchasing property in Australia/New Zealand; paying for overseas purchases,mainly cosmetics; paying tuition and living cost in Australia/New Zealand. 将海外工资汇回澳大利亚/新西兰; 在澳大利亚/新西兰购买房产; 支付海外购物，主要是化妆品; 在澳大利亚/新西兰支付学费和生活费。</div>
        </a-form-model-item>
        <a-form-model-item
        prop="transaction_country_list"
         label="List of countries normally dealing/trading with 请列出您产生交易/贸易的国家或地区">
          <a-textarea
      placeholder=""
      :disabled="disabled"
      v-model='secondForm.transaction_country_list'
      @change='(e)=>{
        handleInput(e,"transaction_country_list")
      }'
      :auto-size="{ minRows: 3, maxRows: 3 }"
    />
        </a-form-model-item>
    </div>
    <div class="step_question_container">
      <h2 class="step_question_title">
       TRANSACTION AND PAYMENT 交易和付款
      </h2>
        <a-form-model-item
        prop="num_of_anual_transactions"
         label="Approx. number of annual transactions 大约每年交易次数是多少">
          <a-input
          :disabled="disabled"
          v-model='secondForm.num_of_anual_transactions'
          @change="(e)=>{
                handleInput(e, 'num_of_anual_transactions')
              }"
          placeholder="">
          </a-input>
        </a-form-model-item>
        <a-form-model-item
        prop="frequency_of_transaction"
        label="Approx. number & frequency of transactions 大约交易频率是多少">
          <a-input
          :disabled="disabled"
          v-model='secondForm.frequency_of_transaction'
          @change="(e)=>{
                handleInput(e, 'frequency_of_transaction')
              }"
          placeholder="">
          </a-input>
          <div class="textarea_desc">I.e. 5 weekly / 3 monthly / 2 quarterly</div>
        </a-form-model-item>
        <a-form-model-item
        prop="total_volumn_of_trasaction_per_year"
        label="Approx. number tade volume (in NZD) 大约每年交易金额是多少（纽币）">
          <a-input
          :disabled="disabled"
          @change="(e)=>{
                handleInput(e, 'total_volumn_of_trasaction_per_year')
              }"
          v-model='secondForm.total_volumn_of_trasaction_per_year' />
        </a-form-model-item>
        <a-form-model-item
        prop="average_volume_of_per_transaction"
        label="Approx. average transaction volume (in NZD) 大约平均每笔交易金额是多少（纽币）">
          <a-input
          :disabled="disabled"
          @change="(e)=>{
                handleInput(e, 'average_volume_of_per_transaction')
              }"
          v-model='secondForm.average_volume_of_per_transaction'/>
        </a-form-model-item>
        <a-form-model-item
        prop="is_money_from_high_risk_country"
        label="Will you remit/receive any money directly to/from a high risk country or indirectly via another country? 您是直接向高风险国家汇出/接收任何资金还是通过其他国家间接汇款？">
          <a-radio-group name="is_money_from_high_risk_country"
          class="modify_antd_radio"
          :disabled="disabled"
          @change="handleRadioSelect"
          v-model="secondForm.is_money_from_high_risk_country">
          <a-radio :value='true'>
            Yes 是
          </a-radio>
          <a-radio :value="false">
            No 否
          </a-radio>
        </a-radio-group>
          <a-form-model-item
          prop="money_from_high_risk_country_specific"
          v-if='is_money_from_high_risk_country == true'
          >
          <a-input
          :disabled="disabled"
          @change="(e)=>{
                handleInput(e, 'money_from_high_risk_country_specific')
              }"
          v-model='secondForm.money_from_high_risk_country_specific' placeholder="Please specific">
          </a-input>
          </a-form-model-item>
        </a-form-model-item>
        <a-form-model-item
        prop="had_trade_with_high_rish_country"
        label="Do you trade with any high risk countries? (directly or via a third party) 您是否与任何高风险国家进行交易？ （直接或通过第三方）">
          <a-radio-group name="had_trade_with_high_rish_country"
          :disabled="disabled"
          class="modify_antd_radio"
          v-model="secondForm.had_trade_with_high_rish_country">
          <a-radio :value='true'>
            Yes 是
          </a-radio>
          <a-radio :value="false">
            No 否
          </a-radio>
        </a-radio-group>
        <a-form-model-item
        prop="trade_with_high_rish_country_specific"
        v-if='had_trade_with_high_rish_country == true'
        >
        <a-input
        :disabled="disabled"
        @change="(e)=>{
                handleInput(e, 'trade_with_high_rish_country_specific')
              }"
          v-model='secondForm.trade_with_high_rish_country_specific' placeholder="Please specific">
          </a-input>
        </a-form-model-item>
        </a-form-model-item>
        <a-form-model-item
        prop="is_pay_for_thrid_party"
        label="Do you make payments to third parties? 您向第三方付款吗？">
          <a-radio-group name="is_pay_for_thrid_party"
          :disabled="disabled"
          class="modify_antd_radio"
          v-model="secondForm.is_pay_for_thrid_party">
          <a-radio :value='true'>
            Yes 是
          </a-radio>
          <a-radio :value="false">
            No 否
          </a-radio>
        </a-radio-group>
        <a-form-model-item
        prop="pay_for_thrid_party_specific"
        v-if='is_pay_for_thrid_party == true'
        >
        <a-input
        :disabled="disabled"
        @change="(e)=>{
                handleInput(e, 'pay_for_thrid_party_specific')
              }"
          v-model='secondForm.pay_for_thrid_party_specific' placeholder="Please specific">
          </a-input>
          </a-form-model-item>
        </a-form-model-item>
    </div>
    </a-form-model>
  </div>
</template>

<script>
// import { getTaxCountry } from '@/api/root'
import { mapActions, mapState, mapGetters } from 'vuex'
import rules from '@/assets/rules/twoRules.js'

export default {
  created () {
    this.rules = rules
  },
  methods: {
    handleRadioSelect (e) {
      const { name, value } = e.target
      this.ChangeFormAction({ content: name, value })
    },
    handleInput (e, content) {
      const { value } = e.target
      const _value = value.replace(/[\u4E00-\u9FA5]/g, '')
      this.ChangeFormAction({ content, value: _value })
    },
    handleSelect (value, content) {
      this.ChangeFormAction({ content, value })
    },
    ...mapActions('personStepTwo', ['ChangeFormAction'])

  },
  data () {
    return {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
      sof_list: [
        {
          id: 1,
          desc: 'Business Income'
        },
        {
          id: 2,
          desc: 'Employment (Salary, Wages, Bonuses, Business Activity)'
        },
        {
          id: 3,
          desc: 'Gifts'
        },
        {
          id: 4,
          desc: 'Inheritance'
        },
        {
          id: 5,
          desc: 'Loan Drawdown'
        },
        {
          id: 6,
          desc: 'Rental Income'
        },
        {
          id: 7,
          desc: 'Sale of Property'
        },
        {
          id: 8,
          desc: 'Sales of Shares'
        },
        {
          id: 9,
          desc: 'Open An Account - Individual & Sole Trader'
        },
        {
          id: 10,
          desc: 'Other'
        }
      ],
      pof_list: [
        {
          id: 1,
          desc: 'Capital injection'
        },
        {
          id: 2,
          desc: 'Commission distribution'
        },
        {
          id: 3,
          desc: 'Educational fees payment'
        },
        {
          id: 4,
          desc: 'Exchange rate hedge'
        },
        {
          id: 5,
          desc: 'Gift(specify - reason and from who)'
        },
        {
          id: 6,
          desc: 'Living expenses'
        },
        {
          id: 7,
          desc: 'Loan'
        },
        {
          id: 8,
          desc: 'Mortgage/loan repayment'
        },
        {
          id: 9,
          desc: 'Overseas travel'
        },
        {
          id: 10,
          desc: 'Payment for freight services'
        },
        {
          id: 11,
          desc: 'Payment for goods/service(specify types of goods/service)'
        },
        {
          id: 12,
          desc: 'Personal investment(specify types of investment)'
        },
        {
          id: 13,
          desc: 'Property maintenance'
        },
        {
          id: 14,
          desc: 'Property purchase'
        },
        {
          id: 15,
          desc: 'Property sale'
        },
        {
          id: 16,
          desc: 'Repatriate overseas revenue back to NZ'
        },
        {
          id: 17,
          desc: 'Business Travel Expense'
        },
        {
          id: 18,
          desc: 'Inter-company Loan, Transfer'
        },
        {
          id: 19,
          desc: 'Reimbursement'
        },
        {
          id: 20,
          desc: 'Salary payment sent home'
        },
        {
          id: 21,
          desc: 'Salary received in foreign currency'
        },
        {
          id: 22,
          desc: 'Transfer of savings'
        },
        {
          id: 23,
          desc: 'Vehicle purchase/maintenance'
        },
        {
          id: 24,
          desc: 'Other'
        }
      ]
    }
  },

  beforeRouteLeave (to, from, next) {
    if (this.disabled) {
      next()
      return
    }
    this.$refs.person_second_form.validate(valid => {
      if (valid) {
        next()
      } else {
        next()
      }
    })
  },
  computed: {
    secondForm () {
      return this.$store.state.personStepTwo
    },
    ...mapGetters({
      disabled: 'login/getComplainceActive'
    }),
    ...mapState('personStepTwo', ['is_money_from_high_risk_country', 'money_from_high_risk_country_specific', 'had_trade_with_high_rish_country', 'num_of_anual_transactions', 'source_of_funds_text', 'trade_with_high_rish_country_specific', 'purpose_of_transaction_text', 'is_pay_for_thrid_party', 'pay_for_thrid_party_specific'])
  }
}
</script>

<style scoped>

.step_container {
  background: rgb(248, 248, 248);
  padding: 0 1rem;
}

.modify_antd_radio >>> .ant-radio-inner{
  width: 26px;
  height: 26px;
}
.modify_antd_radio >>> .ant-radio-inner::after{
  width: 12px;
  left: 6px;
  top: 6px;
  height: 12px;
}
.ant-form >>> label{
  font-size: 15px;
}
.textarea_desc{
  line-height: 20px;
  color:#777;
  margin-bottom: 10px;
}
</style>
