var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step_container"},[_c('a-form-model',{ref:"person_second_form",attrs:{"layout":"horizontal","rules":_vm.rules,"model":_vm.secondForm,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('h1',{staticClass:"step_title"},[_vm._v("ACCOUNT BEHAVIOUR BASELINE 账户行为基准")]),_c('div',{staticClass:"step_question_container"},[_c('h2',{staticClass:"step_question_title"},[_vm._v(" SOURCE OF FUNDS/WEALTH AND PURPOSE OF TRADE 资金来源和交易目的 ")]),_c('a-form-model-item',{attrs:{"prop":"source_of_funds_text","label":"Soure of Funds/Wealth 资金来源"}},[_c('a-select',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.secondForm.source_of_funds_text),callback:function ($$v) {_vm.$set(_vm.secondForm, "source_of_funds_text", $$v)},expression:"secondForm.source_of_funds_text"}},_vm._l((_vm.sof_list),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.desc}},[_vm._v(_vm._s(item.desc))])}),1)],1),_c('a-form-model-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.source_of_funds_text === 'Other'),expression:"source_of_funds_text === 'Other'"}],attrs:{"prop":"source_of_funds_other_text","label":"其他资金来源"}},[_c('a-textarea',{attrs:{"disabled":_vm.disabled,"placeholder":"","auto-size":{ minRows: 3, maxRows: 3 }},on:{"change":function (e){
            _vm.handleInput(e,"source_of_funds_other_text")
          }},model:{value:(_vm.secondForm.source_of_funds_other_text),callback:function ($$v) {_vm.$set(_vm.secondForm, "source_of_funds_other_text", $$v)},expression:"secondForm.source_of_funds_other_text"}}),_c('div',{staticClass:"textarea_desc"},[_vm._v("I.e.Personal savings in bank in Australia/New Zealand; sale of another property in Australia/China; inheritance; health products trading revenue; cosmetics importing and exporting revenue./Employment wages and salaries")])],1),_c('a-form-model-item',{attrs:{"prop":"purpose_of_transaction_text","label":"Purpose of Transaction 换汇目的"}},[_c('a-select',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.secondForm.purpose_of_transaction_text),callback:function ($$v) {_vm.$set(_vm.secondForm, "purpose_of_transaction_text", $$v)},expression:"secondForm.purpose_of_transaction_text"}},_vm._l((_vm.pof_list),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.desc}},[_vm._v(_vm._s(item.desc))])}),1)],1),_c('a-form-model-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.secondForm.purpose_of_transaction_text === 'Other'),expression:"secondForm.purpose_of_transaction_text === 'Other'"}],attrs:{"prop":"purpose_of_transaction_other_text","label":"其他换汇目的"}},[_c('a-textarea',{attrs:{"placeholder":"","disabled":_vm.disabled,"auto-size":{ minRows: 3, maxRows: 3 }},on:{"change":function (e){
      _vm.handleInput(e,"purpose_of_transaction_other_text")
    }},model:{value:(_vm.secondForm.purpose_of_transaction_other_text),callback:function ($$v) {_vm.$set(_vm.secondForm, "purpose_of_transaction_other_text", $$v)},expression:"secondForm.purpose_of_transaction_other_text"}}),_c('div',{staticClass:"textarea_desc"},[_vm._v("I.e.Repatriate overseas salary back to Australia/New Zealand; purchasing property in Australia/New Zealand; paying for overseas purchases,mainly cosmetics; paying tuition and living cost in Australia/New Zealand. 将海外工资汇回澳大利亚/新西兰; 在澳大利亚/新西兰购买房产; 支付海外购物，主要是化妆品; 在澳大利亚/新西兰支付学费和生活费。")])],1),_c('a-form-model-item',{attrs:{"prop":"transaction_country_list","label":"List of countries normally dealing/trading with 请列出您产生交易/贸易的国家或地区"}},[_c('a-textarea',{attrs:{"placeholder":"","disabled":_vm.disabled,"auto-size":{ minRows: 3, maxRows: 3 }},on:{"change":function (e){
      _vm.handleInput(e,"transaction_country_list")
    }},model:{value:(_vm.secondForm.transaction_country_list),callback:function ($$v) {_vm.$set(_vm.secondForm, "transaction_country_list", $$v)},expression:"secondForm.transaction_country_list"}})],1)],1),_c('div',{staticClass:"step_question_container"},[_c('h2',{staticClass:"step_question_title"},[_vm._v(" TRANSACTION AND PAYMENT 交易和付款 ")]),_c('a-form-model-item',{attrs:{"prop":"num_of_anual_transactions","label":"Approx. number of annual transactions 大约每年交易次数是多少"}},[_c('a-input',{attrs:{"disabled":_vm.disabled,"placeholder":""},on:{"change":function (e){
              _vm.handleInput(e, 'num_of_anual_transactions')
            }},model:{value:(_vm.secondForm.num_of_anual_transactions),callback:function ($$v) {_vm.$set(_vm.secondForm, "num_of_anual_transactions", $$v)},expression:"secondForm.num_of_anual_transactions"}})],1),_c('a-form-model-item',{attrs:{"prop":"frequency_of_transaction","label":"Approx. number & frequency of transactions 大约交易频率是多少"}},[_c('a-input',{attrs:{"disabled":_vm.disabled,"placeholder":""},on:{"change":function (e){
              _vm.handleInput(e, 'frequency_of_transaction')
            }},model:{value:(_vm.secondForm.frequency_of_transaction),callback:function ($$v) {_vm.$set(_vm.secondForm, "frequency_of_transaction", $$v)},expression:"secondForm.frequency_of_transaction"}}),_c('div',{staticClass:"textarea_desc"},[_vm._v("I.e. 5 weekly / 3 monthly / 2 quarterly")])],1),_c('a-form-model-item',{attrs:{"prop":"total_volumn_of_trasaction_per_year","label":"Approx. number tade volume (in NZD) 大约每年交易金额是多少（纽币）"}},[_c('a-input',{attrs:{"disabled":_vm.disabled},on:{"change":function (e){
              _vm.handleInput(e, 'total_volumn_of_trasaction_per_year')
            }},model:{value:(_vm.secondForm.total_volumn_of_trasaction_per_year),callback:function ($$v) {_vm.$set(_vm.secondForm, "total_volumn_of_trasaction_per_year", $$v)},expression:"secondForm.total_volumn_of_trasaction_per_year"}})],1),_c('a-form-model-item',{attrs:{"prop":"average_volume_of_per_transaction","label":"Approx. average transaction volume (in NZD) 大约平均每笔交易金额是多少（纽币）"}},[_c('a-input',{attrs:{"disabled":_vm.disabled},on:{"change":function (e){
              _vm.handleInput(e, 'average_volume_of_per_transaction')
            }},model:{value:(_vm.secondForm.average_volume_of_per_transaction),callback:function ($$v) {_vm.$set(_vm.secondForm, "average_volume_of_per_transaction", $$v)},expression:"secondForm.average_volume_of_per_transaction"}})],1),_c('a-form-model-item',{attrs:{"prop":"is_money_from_high_risk_country","label":"Will you remit/receive any money directly to/from a high risk country or indirectly via another country? 您是直接向高风险国家汇出/接收任何资金还是通过其他国家间接汇款？"}},[_c('a-radio-group',{staticClass:"modify_antd_radio",attrs:{"name":"is_money_from_high_risk_country","disabled":_vm.disabled},on:{"change":_vm.handleRadioSelect},model:{value:(_vm.secondForm.is_money_from_high_risk_country),callback:function ($$v) {_vm.$set(_vm.secondForm, "is_money_from_high_risk_country", $$v)},expression:"secondForm.is_money_from_high_risk_country"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" Yes 是 ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" No 否 ")])],1),(_vm.is_money_from_high_risk_country == true)?_c('a-form-model-item',{attrs:{"prop":"money_from_high_risk_country_specific"}},[_c('a-input',{attrs:{"disabled":_vm.disabled,"placeholder":"Please specific"},on:{"change":function (e){
              _vm.handleInput(e, 'money_from_high_risk_country_specific')
            }},model:{value:(_vm.secondForm.money_from_high_risk_country_specific),callback:function ($$v) {_vm.$set(_vm.secondForm, "money_from_high_risk_country_specific", $$v)},expression:"secondForm.money_from_high_risk_country_specific"}})],1):_vm._e()],1),_c('a-form-model-item',{attrs:{"prop":"had_trade_with_high_rish_country","label":"Do you trade with any high risk countries? (directly or via a third party) 您是否与任何高风险国家进行交易？ （直接或通过第三方）"}},[_c('a-radio-group',{staticClass:"modify_antd_radio",attrs:{"name":"had_trade_with_high_rish_country","disabled":_vm.disabled},model:{value:(_vm.secondForm.had_trade_with_high_rish_country),callback:function ($$v) {_vm.$set(_vm.secondForm, "had_trade_with_high_rish_country", $$v)},expression:"secondForm.had_trade_with_high_rish_country"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" Yes 是 ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" No 否 ")])],1),(_vm.had_trade_with_high_rish_country == true)?_c('a-form-model-item',{attrs:{"prop":"trade_with_high_rish_country_specific"}},[_c('a-input',{attrs:{"disabled":_vm.disabled,"placeholder":"Please specific"},on:{"change":function (e){
              _vm.handleInput(e, 'trade_with_high_rish_country_specific')
            }},model:{value:(_vm.secondForm.trade_with_high_rish_country_specific),callback:function ($$v) {_vm.$set(_vm.secondForm, "trade_with_high_rish_country_specific", $$v)},expression:"secondForm.trade_with_high_rish_country_specific"}})],1):_vm._e()],1),_c('a-form-model-item',{attrs:{"prop":"is_pay_for_thrid_party","label":"Do you make payments to third parties? 您向第三方付款吗？"}},[_c('a-radio-group',{staticClass:"modify_antd_radio",attrs:{"name":"is_pay_for_thrid_party","disabled":_vm.disabled},model:{value:(_vm.secondForm.is_pay_for_thrid_party),callback:function ($$v) {_vm.$set(_vm.secondForm, "is_pay_for_thrid_party", $$v)},expression:"secondForm.is_pay_for_thrid_party"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" Yes 是 ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" No 否 ")])],1),(_vm.is_pay_for_thrid_party == true)?_c('a-form-model-item',{attrs:{"prop":"pay_for_thrid_party_specific"}},[_c('a-input',{attrs:{"disabled":_vm.disabled,"placeholder":"Please specific"},on:{"change":function (e){
              _vm.handleInput(e, 'pay_for_thrid_party_specific')
            }},model:{value:(_vm.secondForm.pay_for_thrid_party_specific),callback:function ($$v) {_vm.$set(_vm.secondForm, "pay_for_thrid_party_specific", $$v)},expression:"secondForm.pay_for_thrid_party_specific"}})],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }